import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Container,
    Box,
    Typography,
    Grid,
    CardContent,
} from "@mui/material";
import {
    containersBorderRadius,
    imgSize,
} from "../pageSettings";
import {useTheme} from "@mui/material/styles";

interface TextSectionProps {
    text: string;
}

export const CenterBox: React.FC<{ children: React.ReactNode }> = ({
                                                                       children,
                                                                   }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}
        >
            {children}
        </Box>
    );
};
const TextSection: React.FC<TextSectionProps> = ({text}) => {
    return (
        <CenterBox>
            <CardContent>
                <Typography variant="h4" component="h4" sx={{textAlign: "center"}}>
                    {text}
                </Typography>
            </CardContent>
        </CenterBox>
    );
};

const LocalGridItem: React.FC<{ children: React.ReactNode }> = ({
                                                                    children,
                                                                }) => {
    return (
        <Grid item xs={5} sm={6} md={6}>
            {children}
        </Grid>
    );
};

const IntroSection: React.FC = () => {
    const theme = useTheme();
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000

    };
    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: "2rem",
                marginBottom: "5rem",
            }}
        >
            <Box>
                <Slider {...settings}>
                    <div>
                        <Grid container spacing={2}>
                            <LocalGridItem>
                                <CenterBox>
                                    <Box
                                        component="img"
                                        src="pozycjonowanie.png"
                                        alt="Telefon, na którym stoi globus z pinezką pozycjonowania"
                                        sx={{
                                            width: imgSize,
                                            borderRadius: containersBorderRadius,
                                            boxShadow: `0px 0px 15px 0px ${theme.palette.primary.dark}`,
                                        }}
                                    />
                                </CenterBox>
                            </LocalGridItem>
                            <LocalGridItem>
                                <TextSection
                                    text={"Popraw widoczność Twojej firmy w internecie."}
                                />
                            </LocalGridItem>
                        </Grid>
                    </div>
                    <div>
                        <Grid container spacing={2}>

                            <LocalGridItem>
                                <CenterBox>
                                    <Box
                                        component="img"
                                        src="nowi klienci.png"
                                        alt="ludzie na ulicy"
                                        sx={{
                                            width: imgSize,
                                            borderRadius: containersBorderRadius,
                                            boxShadow: `0px 0px 15px 0px ${theme.palette.primary.dark}`,
                                        }}
                                    />
                                </CenterBox>
                            </LocalGridItem>
                            <LocalGridItem>
                                <TextSection text={"Znajdź nowych klientów"}/>
                            </LocalGridItem>
                        </Grid>
                    </div>
                    <div>
                        <Grid container spacing={2}>
                            <LocalGridItem>
                                <CenterBox>
                                    <Box
                                        component="img"
                                        src="dochody.png"
                                        alt="kobieta z laptopem, spadające banknoty"
                                        sx={{
                                            width: imgSize,
                                            borderRadius: containersBorderRadius,
                                            boxShadow: `0px 0px 15px 0px ${theme.palette.primary.dark}`,
                                        }}
                                    />
                                </CenterBox>
                            </LocalGridItem>
                            <LocalGridItem>
                                <TextSection text={"Zwiększ swoje przychody."}/>
                            </LocalGridItem>
                        </Grid>
                    </div>
                </Slider>
            </Box>
        </Container>
    );
};

export default IntroSection;
