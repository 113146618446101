import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {TextField, Button, Box, Typography, Snackbar, Alert} from "@mui/material";
import emailjs from "emailjs-com";
import {useTheme} from "@mui/material/styles";
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface IFormInput {
    name: string;
    email: string;
    message: string;
    company: string;
}

function ContactForm() {
    const theme = useTheme();

    const [successInfo, setSuccessInfo] = useState<string | null>(null);
    const [errorInfo, setErrorInfo] = useState<string | null>(null);
    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IFormInput>();
    const textFieldSX = {
        "& .MuiOutlinedInput-root": {
            backgroundColor: theme.palette.primary.main,
            color: "gray",
            "& fieldset": {
                borderColor: "gray",
            },
            "&:hover fieldset": {
                borderColor: theme.palette.secondary.light,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.secondary.light,
                borderWidth: "2px",
            },
            "&.Mui-focused": {
                backgroundColor: theme.palette.primary.main,
            },
            "&.MuiInputBase-input": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    };
    const onSubmit = (data: any) => {
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
                data,
                process.env.REACT_APP_EMAILJS_USER_ID || "",
            )
            .then((response) => {
                    if (response.status === 200) {
                        setErrorInfo(null);
                        setSuccessInfo("Wiadomość została wysłana");
                    } else {
                        setSuccessInfo(null);
                        setErrorInfo("Wystąpił błąd podczas wysyłania wiadomości");
                    }
                }
            )
            .catch((error) => {
                console.error(error);
                setSuccessInfo(null);
                setErrorInfo("Wystąpił błąd podczas wysyłania wiadomości");

            });
    };

    return (
        <Box
            sx={{
                borderTop: `1px solid ${theme.palette.secondary.light}`,
                margin: "5rem 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    maxWidth: 800,
                }}
            >
                <Typography
                    variant="h4"
                    marginTop={"2rem"}
                    component="h1"
                    align="center"
                    gutterBottom
                >
                    Skontaktuj się z nami
                </Typography>
                <Box sx={{display: "flex"}}>
                    <Box sx={{margin: "0 1rem"}}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{required: "To pole jest wymagane"}}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    color="info"
                                    label="Imię"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.name}
                                    helperText={errors.name ? errors.name.message : ""}
                                    sx={textFieldSX}
                                />
                            )}
                        />

                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "To pole jest wymagane",
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Wprowadź prawidłowy adres email",
                                },
                            }}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    margin={"dense"}
                                    color="info"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.email}
                                    helperText={errors.email ? errors.email.message : ""}
                                    sx={textFieldSX}
                                />
                            )}
                        />
                        <Controller
                            name="company"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    margin={"dense"}
                                    color="info"
                                    label="Nazwa i Adres Firmy (opcjonalnie)"
                                    variant="outlined"
                                    fullWidth
                                    sx={textFieldSX}
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        name="message"
                        control={control}
                        defaultValue=""
                        rules={{required: "To pole jest wymagane"}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                color="info"
                                label="Wiadomość"
                                variant="outlined"
                                multiline
                                rows={7}
                                fullWidth
                                error={!!errors.message}
                                helperText={errors.message ? errors.message.message : ""}
                                sx={textFieldSX}
                            />
                        )}
                    />
                </Box>

                <Button type="submit" variant="contained" color="secondary" fullWidth>
                    Wyślij
                </Button>
            </Box>
            <Box sx={{ display: "flex" }} >
                {successInfo && (
                    <Alert severity="success" icon={<FavoriteOutlinedIcon fontSize={"large"} sx={{ color: `${theme.palette.success.light}` }} />} sx={{mt: 2, width: '100%', backgroundColor: `${theme.palette.success.main}`, textAlign: "center"}}>
                        <Typography color={theme.palette.text.secondary} variant={"h3"} component={"h3"} sx={{ fontWeight: "bolder", lineHeight: 1.5 }}>{successInfo}</Typography>
                    </Alert>
                )}
                {errorInfo && (
                    <Alert severity="error" icon={<CloseOutlinedIcon fontSize={"large"} sx={{ color: `${theme.palette.error.dark}` }}/>} sx={{mt: 2, width: '100%', backgroundColor: `${theme.palette.warning.main}`}}>
                        <Typography color={theme.palette.text.secondary} variant={"h3"} component={"h3"} sx={{ fontWeight: "bolder", lineHeight: 1.2 }}>{errorInfo}</Typography>
                    </Alert>
                )}
            </Box>
        </Box>
    );
}

export default ContactForm;
