import React from "react";
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {
    headerHeight,
    containersBorderRadius,
} from "../pageSettings";

const HeroSection: React.FC = () => {
    const theme = useTheme();
    return (
        <Box id={"main"} sx={{width: "100%", padding: "0", margin: "0"}}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "40vh",
                    minWidth: "100%",
                    textAlign: "center",
                    padding: "2rem",
                    margin: "0",
                    borderRadius: containersBorderRadius,
                    color: "white",
                    paddingTop: headerHeight,
                    background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, #202020)`,
                }}
            >
                <Typography variant="h1" component="h1" gutterBottom>
                    Zbuduj swoją markę z Visioptimize
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Profesjonalne SEO wizytówek google i stron internetowych
                </Typography>
            </Box>
        </Box>
    );
};

export default HeroSection;
